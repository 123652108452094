@import "helpers/reset";
@import "helpers/slick";
@import "helpers/slick-theme";
@import "helpers/mixins";
@import "helpers/vars";
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700&subset=cyrillic-ext');
//common rules

// Global style
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1
  }
}

* {
  font-family: 'PT Sans', sans-serif;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $title;
  margin-bottom: 5px;

  a {
    color: $title;
    @include transite();

    &:hover {
      color: $btn;
      @include transite()
    }
  }
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul, ol {
  margin-bottom: 5px;
}

ul {
  list-style: disc;
  padding-left: 20px;
}

ol {
  list-style: decimal;
  padding-left: 20px;
}

em {
  font-style: italic;
  color: #444;
}

strong {
  font-weight: bold;
}

table {
  display: block;
  overflow: auto;
  max-width: 100%;
  margin-bottom: 20px;

  tbody {
    tr {
      &:last-child {
        td {
          border-bottom: 1px solid #bbb;
        }
      }
      td {
        border-right: 1px solid #bbb;
        border-top: 1px solid #bbb;
        padding: 8px;
        min-width: 150px;

        &:first-child {
          border-left: 1px solid #bbb;
        }

      }
    }
  }
}

//img{
//  width: 100%;
//  height: auto;
//}

.slick-prev, .slick-next {
  &:before {
    content: ''
  }
}

// Global style

body {
  opacity: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: black;

  &.is-active {
    overflow-y: auto;
    animation-name: fadeIn;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
  }

  .wrp {
    margin: 0 auto;
    padding: 0 20px;
    width: 1200px;

    @include respond-to(960) {
      width: 100%;
    }
    @include respond-to(768) {

    }
    @include respond-to(320) {

    }
  }
  nav {
    background: #1b1d1f;

    .wrp {
      .toggle_nav {
        color: $default;
        display: none;
        font-size: 22px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px;
        cursor: pointer;

        &:before {
          border-bottom: 10px double white;
          border-top: 3px solid white;
          height: 4px;
          width: 25px;
          display: inline-block;
          content: '';
          position: relative;
          margin-right: 10px;
          cursor: pointer;
        }

        @include respond-to(320) {
          display: block;
        }
      }
      .region-top-nav {
        @include respond-to(320) {
          display: none;
        }
        .content {
          ul {
            display: flex;
            white-space: nowrap;
            justify-content: space-between;
            margin: 0;
            padding: 0;

            @include respond-to(320) {
              display: inline-block;
            }

            li.expanded {
              position: relative;

              &:hover {
                ul.menu {
                  opacity: 1;
                  visibility: visible;

                  @include respond-to(320) {
                    position: relative;

                  }
                }
              }

              ul.menu {
                position: absolute;
                display: block;
                background: #1b1d1f;
                z-index: 1;
                opacity: 0;
                width: 300px;
                visibility: hidden;
                left: -16px;

                @include transite();

                @include respond-to(320) {
                  position: relative;
                  opacity: 1;
                  visibility: visible;

                }

                li {
                  margin: 10px 15px;
                }

                li.first {
                  margin-top: 20px;
                }

                li.leaf {
                  &:before {
                    content: '-';
                    color: #fff;
                    padding-right: 5px;
                    font-weight: bold;
                  }
                }
              }
            }

            li.active-trail {
              > a {
                color: $btn;
              }
            }

            li {
              margin: 20px 0;

              a {
                color: #fff;
                font-weight: bold;
                font-size: 16px;
                text-transform: uppercase;
                @include transite();

                &:hover {
                  color: $btn;
                }
              }
            }
          }
        }
      }
    }
  }

  header {
    padding: 20px 0;

    .wrp {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond-to(320) {
        flex-direction: column;
      }
    }
    .logo {
      img {
        @include respond-to(768) {
          width: 250px;
        }
      }
    }
    input {
      border: 1px solid #bdbdbd;
      height: 30px;
    }
    input.form-submit {
      background: #eecb34;
    }
    .region-header {
      margin-right: 20px;

      @include respond-to(320) {
        margin: 0;
      }

      #block-block-2{

        .content{

          p {

            a {
              color: #1c1c1c;
              font-size: 18px;
              font-weight: bold;
              margin: 0 0 16px 30px;
              white-space: nowrap;
              @include transite();

              @include respond-to(768) {
                font-size: 14px;
                margin: 0 0 16px 2px;
              }
              @include respond-to(320) {
                margin: 20px 0 16px 2px;
              }

              &:hover {
                color: $btn;
              }
            }
          }
        }
      }

      .content {
        display: flex;
        justify-content: flex-end;
        @include respond-to(320) {
          justify-content: space-around;
        }

        .views-exposed-widgets {
          display: flex;
          flex-wrap: nowrap;

          @include respond-to(320) {
            margin: 0 auto;
          }
          input.form-text {
            width: 370px;
            @include respond-to(768) {
              width: 213px;
            }
            @include respond-to(320) {
              width: 230px;
            }
          }
          input.form-submit {
            background: $btn url(../img/icons/search.png) no-repeat center;
            display: block;
            text-indent: -99999px;
            width: 31px;
            @include transite();

            &:hover {
              background: $title url(../img/icons/search_hover.png) no-repeat center;
              display: block;
              text-indent: -99999px;
              width: 31px;
              @include transite();
            }
          }
        }

      }
    }

    .region-header-btn {

      .content {

        .contact_us {
          a.contact {
            color: $title;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            margin-bottom: 18px;
            @include transite();

            @include respond-to(320) {
              justify-content: center;
              margin: 20px;
            }

            &:before {
              content: '';
              background: url(../img/e-mail.png) no-repeat;
              width: 27px;
              height: 17px;
              display: inline-block;
              margin-right: 15px;
              @include transite();
            }

            &:hover {
              color: $btn;
            }
            &:hover:before {
              background: url(../img/e-mail-hover.png) no-repeat;
            }
          }
        }

        .price {
          a {
            background: $btn;
            color: $title;
            display: block;
            font-size: 16px;
            font-weight: bold;
            padding: 5px 20px;
            text-transform: uppercase;
            cursor: pointer;
            @include transite();

            @include respond-to(768) {
              font-size: 14px;
            }

            &:hover {
              background: $title;
              color: $default;
              @include transite();
            }
          }
        }
      }
    }
  }

  .category_mobile {
    display: none;

    @include respond-to(320) {
      display: block;
      background: $title;
      color: $default;
    }

    .wrp {
      .toggle_cat {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0;
        cursor: pointer;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &:before {
          content: '';
          background: url(../img/kvadrat.png) no-repeat;
          width: 21px;
          height: 21px;
          padding-right: 10px;
        }
      }

      .sidebar {
        display: none;

        p.block-title {
          display: none;
        }
        ul {
          padding-left: 0px;
          li {
            a {
              color: $default;
              margin-bottom: 15px;
              display: block;
              font-size: 18px;
              font-weight: bold;
              @include transite();

              &:hover {
                color: $btn;
                @include transite();
              }
            }
            ul {
              padding-left: 10px;

              li {
                a {
                  &:before {
                    content: '-';
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .slider {
    .content {
      .slick-slide {
        .slide {
          height: 526px;

          .wrpslide {
            background: rgba(27, 29, 31, 0.8);
            width: 570px;
            height: 390px;
            left: -170px;
            position: relative;
            top: 67px;
            padding: 40px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include respond-to(768) {
              left: -10px;
            }

            .panel {
              width: 350px;

              .title {
                color: $default;
                font-size: 36px;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 30px;
              }
              .text {
                p {
                  color: $default;
                  font-size: 18px;
                  font-weight: bold;
                }
              }
            }
            .btn {
              a {
                background: $btn;
                color: $title;
                font-weight: bold;
                padding: 10px 27px;
                text-transform: uppercase;
                @include transite();

                &:hover {
                  background: $title;
                  color: $default;
                  @include transite();
                }
              }
            }
          }
        }
      }

      .slick-dots {
        bottom: 18px;

        li {
          background: $default;
          border-radius: 100%;
          height: 12px;
          width: 12px;
          @include transite();

          &:hover {
            @include transite();
            background: rgba(254, 204, 2, 0.5)
          }
        }
        .slick-active {
          background: $btn;

          &:hover {
            background: $btn;
          }
        }
      }

      .slick-next {
        background: $btn url(../img/icons/slideshow_btn_next.png) no-repeat center;
        left: calc(50% + 115px);
        top: calc(50% + -168px);
        height: 57px;
        width: 57px;

        @include respond-to(768) {
          left: calc(50% + 275px);
        }
      }
      .slick-prev {
        background: $default url(../img/icons/slideshow_btn_prev.png) no-repeat center;
        left: calc(50% + 57px);
        top: calc(50% + -168px);
        height: 57px;
        width: 57px;
        z-index: 1;

        @include respond-to(768) {
          left: calc(50% + 217px);
        }
      }

      .slick-prev, .slick-next {
        &:hover {
          opacity: .88;
        }
      }
    }
    @include respond-to(960) {
      width: 100%;
    }
    @include respond-to(768) {

    }
    @include respond-to(320) {
      display: none;
    }

    .slick-dots li button {
      &:before {
        content: '';
      }
    }
  }

  .front_content {
    p.block-title {
      color: $title;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 72px;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      white-space: nowrap;

      @include respond-to(768) {
        font-size: 28px;
      }

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-right: 30px;
      }
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-left: 30px;
      }

    }
    .content {
      .preimuschestva {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 65px;

        li {
          text-align: center;
          width: 200px;

          img {

            @include respond-to(768) {
              width: 100px;
              height: 100px;
            }
          }

          span {
            display: block;
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase;
            margin-top: 42px;
            color: $title;

            @include respond-to(768) {
              font-size: 16px;
            }
          }
        }
      }
    }
    @include respond-to(960) {
      width: 100%;
    }
    @include respond-to(768) {

    }
    @include respond-to(320) {
      display: none;
    }
  }

  .categories {
    background: $alt_background;

    p.block-title {
      color: $title;
      font-size: 36px;
      font-weight: bold;
      padding: 39px 0;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      white-space: nowrap;

      @include respond-to(768) {
        font-size: 28px;
      }
      @include respond-to(320) {
        font-size: 22px;
      }

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-right: 30px;
      }
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-left: 30px;
      }
    }

    .content {
      .view-cats {
        .view-content {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          @include respond-to(960) {
            justify-content: space-evenly;
          }

          .views-row {
            display: flex;
            flex-direction: column;
            text-align: center;

            @include respond-to(960) {
              margin-bottom: 38px;
            }

            &:hover {

              a {
                color: $btn;
                @include transite();

                img {
                  @include transite();
                  filter: sepia(100%);
                }
              }
            }

            a {
              color: #3e3e3e;
              font-size: 20px;
              font-weight: bold;
              text-transform: uppercase;
              max-width: 270px;
              @include transite();

              @include respond-to(320) {
                font-size: 16px;
              }

              img {
                margin-bottom: 8px;
                @include transite()
              }
            }
          }
        }
        .more-link {
          text-align: center;
          display: flex;
          justify-content: center;

          a {
            text-transform: uppercase;
            color: $title;
            font-weight: bold;
            margin-top: 46px;
            margin-bottom: 67px;
            @include transite();

            &:before {
              content: '';
              background: url(../img/more.png) no-repeat;
              width: 68px;
              height: 68px;
              display: block;
              margin: 0 auto;
              margin-bottom: 20px;
            }

            &:hover {
              filter: drop-shadow(4px 4px 4px #666);
            }

            @include respond-to(960) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .products {
    img {
      width: 100%;
      height: auto;
    }
    p.block-title {
      color: $title;
      font-size: 36px;
      font-weight: bold;
      padding: 45px 0 44px;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      white-space: nowrap;

      @include respond-to(768) {
        font-size: 28px;
      }
      @include respond-to(320) {
        font-size: 22px;
      }

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-right: 30px;
      }
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-left: 30px;
      }
    }

    .content {
      .view-popular-products {
        .view-content {
          margin: 0 auto;
          width: 1100px;

          @include respond-to(960) {
            width: 840px;
          }
          @include respond-to(768) {
            width: 650px;
          }
          @include respond-to(320) {
            width: 200px;
          }

          .views-row {
            border: 1px solid #c4c4c4;
            text-align: center;
            max-width: 215px;
            min-height: 380px;
            box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0);
            z-index: 1;
            position: relative;

            @include transite();

            &:hover {
              box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.5);
              z-index: 1;
              position: relative;
            }

            .views-field-title {
              margin-top: 10px;

              a {
                color: #323946;
                font-weight: bold;
                text-transform: uppercase;
                padding: 0 5px;
              }
            }
            .views-field-field-price {
              color: #323946;
              font-weight: bold;
              text-transform: uppercase;
              font-size: 24px;
              margin-top: 4px;
            }
            .views-field-view-node {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin: 17px 32px 22px;

              a {
                background: $title;
                color: $default;
                font-weight: bold;
                text-transform: uppercase;
                padding: 10px;
                display: block;
                @include transite();

                &:hover {
                  background: $btn;
                  color: $title;

                }
              }
            }
          }
        }
      }
      .slick-prev, .slick-next {
        width: 12px;
        height: 19px;
      }
      .slick-prev {
        background: url(../img/icons/prev.png) no-repeat center;
        left: 0;
        margin-left: -20px;
        @include transite();

        &:hover {
          background: url(../img/icons/prev_hover.png) no-repeat center;
        }
      }
      .slick-next {
        background: url(../img/icons/next.png) no-repeat center;
        right: 0;
        margin-right: -20px;
        @include transite();

        &:hover {
          background: url(../img/icons/next_hover.png) no-repeat center;
        }
      }
    }
    .slick-track {
      margin-bottom: 5px;

      .slick-slide {
        padding: 0 10px;
      }
    }
  }
  .uslugi {
    background: $alt_background;
    padding-top: 49px;
    margin-top: 45px;

    img {
      width: 100%;
      height: auto;
    }

    .block-title {
      color: $title;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 45px;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      white-space: nowrap;

      @include respond-to(768) {
        font-size: 28px;
      }
      @include respond-to(320) {
        font-size: 22px;
      }

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-right: 30px;
      }
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-left: 30px;
      }
    }
    .content {
      .view-content {
        margin: 0 auto;
        width: 1100px;

        @include respond-to(960) {
          width: 840px;
        }
        @include respond-to(768) {
          width: 650px;
        }
        @include respond-to(320) {
          width: 200px;
        }
        .slick-slide {
          padding: 0 10px;
        }
        .views-field-title {
          text-align: center;
          margin-top: 26px;

          a {
            color: $title;
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            @include transite();

            &:hover {
              color: $btn;
              @include transite();
            }
          }
        }
      }
      .more-link {
        text-align: center;
        display: flex;
        justify-content: center;

        a {
          text-transform: uppercase;
          color: $title;
          font-weight: bold;
          margin-top: 46px;
          margin-bottom: 67px;
          @include transite();

          &:before {
            content: '';
            background: url(../img/more.png) no-repeat;
            width: 68px;
            height: 68px;
            display: block;
            margin: 0 auto;
            margin-bottom: 20px;
          }
          &:hover {
            filter: drop-shadow(4px 4px 4px #666);
          }
        }
      }
      .slick-prev, .slick-next {
        width: 12px;
        height: 19px;
        margin-top: -40px;
      }
      .slick-prev {
        background: url(../img/icons/prev.png) no-repeat center;
        left: 0;
        margin-left: -20px;
        @include transite();

        &:hover {
          background: url(../img/icons/prev_hover.png) no-repeat center;
        }
      }
      .slick-next {
        background: url(../img/icons/next.png) no-repeat center;
        right: 0;
        margin-right: -20px;
        @include transite();

        &:hover {
          background: url(../img/icons/next_hover.png) no-repeat center;
        }
      }
    }
  }
  .about {
    padding-top: 49px;
    padding-bottom: 65px;

    .block-title {
      color: $title;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 75px;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      white-space: nowrap;

      @include respond-to(768) {
        font-size: 28px;
      }
      @include respond-to(320) {
        font-size: 22px;
      }

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-right: 30px;
      }
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-left: 30px;
      }
    }
    .content {
      ul.work {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond-to(320) {
          flex-direction: column;
        }

        li.iconwork {
          width: 184px;
          text-align: center;
          display: block;
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
          color: #181a1c;

          img {
            margin-bottom: 10px;
          }

          span {
            display: block;
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            color: $title;
            margin-top: 16px;

            @include respond-to(320) {
              font-size: 16px;
            }
          }
        }
        li.iconwork {
          img {
            @include respond-to(960) {
              width: 140px;
              height: auto;
            }
            @include respond-to(768) {
              width: 110px;
            }
            @include respond-to(320) {
              width: 170px;
            }
          }
        }
        li.strelka {
          margin-bottom: 60px;
          @include respond-to(320) {
            transform: rotate(90deg);
            margin: 30px;

          }
        }
      }
    }
  }
  .news {
    background: $alt_background;
    padding-top: 49px;

    p.block-title {
      color: $title;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 49px;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      white-space: nowrap;

      @include respond-to(768) {
        font-size: 28px;
      }
      @include respond-to(320) {
        font-size: 22px;
      }

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-right: 30px;
      }
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: $btn;
        margin-left: 30px;
      }

    }
    .content {
      .view-content {
        margin: 0 auto;
        width: 1100px;

        @include respond-to(960) {
          width: 840px;
        }
        @include respond-to(768) {
          width: 650px;
        }
        @include respond-to(320) {
          width: 200px;
        }

        .slick-list {
          text-align: center;

          .slick-slide {
            a {
              @include transite();

              &:hover {
                color: $btn;
              }
            }

            .views-row {
              span.field-content {
                color: $title;
                font-weight: bold;

                a {
                  color: $title;

                  &:hover {
                    color: $btn;
                  }
                  .title {
                    margin-bottom: 20px;
                    width: 140px;
                  }
                  span {
                    display: block;
                  }
                }
              }
              .views-field {
                display: flex;
                justify-content: center;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
    .slick-slide {
      padding: 0 10px;
    }
    .slick-prev, .slick-next {
      background: #000;
      width: 12px;
      height: 19px;
      top: 38%;

      @include respond-to(320) {
        top: 34%;
      }
    }

    .slick-prev {
      background: url(../img/icons/prev.png) no-repeat center;
      left: 0;
      margin-left: -20px;
      @include transite();

      &:hover {
        background: url(../img/icons/prev_hover.png) no-repeat center;
      }
    }
    .slick-next {
      background: url(../img/icons/next.png) no-repeat center;
      right: 0;
      margin-right: -20px;
      @include transite();

      &:hover {
        background: url(../img/icons/next_hover.png) no-repeat center;
      }
    }
    .more-link {
      text-align: center;
      display: flex;
      justify-content: center;

      a {
        text-transform: uppercase;
        color: $title;
        font-weight: bold;
        margin-top: 38px;
        margin-bottom: 67px;
        @include transite()

        &:before {
          content: '';
          background: url(../img/more.png) no-repeat;
          width: 68px;
          height: 68px;
          display: block;
          margin: 0 auto;
          margin-bottom: 20px;
        }
        &:hover {
          filter: drop-shadow(4px 4px 4px #666);
        }
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  .breadcrumb {
    padding-bottom: 0;
    margin-top: 15px;

    @include respond-to(320) {
      display: none;
    }

    .inline {
      a {
        color: $title;
      }
    }
    .last {
      color: #888;

      a {
        color: #888;
      }
    }
  }
  .main_content {
    padding: 30px 0;
    flex: 1;

    .container {
      display: flex;

      .sidebar {
        width: 100%;
        max-width: 200px;
        margin-right: 30px;

        @include respond-to(320) {
          display: none;
        }

        p.block-title {
          color: $title;
          font-size: 26px;
          font-weight: bold;
          margin-bottom: 27px;
          text-align: center;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          white-space: nowrap;

          &:before {
            content: '';
            width: 100%;
            height: 1px;
            background: $btn;
            margin-right: 20px;
          }
          &:after {
            content: '';
            width: 100%;
            height: 1px;
            background: $btn;
            margin-left: 20px;
          }
        }
        .content {
          > ul.menu {
            > li {
              margin-bottom: 16px;

              &:before {
                content: '';
                background: url(../img/icons/cat_dots.png) no-repeat;
                width: 5px;
                height: 9px;
                display: inline-block;
                margin-right: 18px;
              }
              > ul {
                margin-left: 20px;

                > li {
                  margin-top: 16px;

                  &:before {
                    content: '';
                    background: url(../img/icons/cat_dots.png) no-repeat;
                    width: 5px;
                    height: 9px;
                    display: inline-block;
                    margin-right: 18px;
                  }
                  > ul {
                    margin-left: 20px;

                    > li {
                      margin-top: 16px;

                      &:before {
                        content: '';
                        background: url(../img/icons/cat_dots.png) no-repeat;
                        width: 5px;
                        height: 9px;
                        display: inline-block;
                        margin-right: 18px;
                      }
                    }
                  }
                }
              }
              a {
                font-size: 18px;
              }
            }
            li {
              a {
                color: $title;
                @include transite()

                &:hover {
                  color: $btn;
                  @include transite()
                }
              }
            }
          }
        }
      }
      .content_page {
        width: 100%;
        max-width: 930px;

        h1.title {
          font-size: 26px;
          margin-bottom: 23px;
          color: $title;
          text-transform: uppercase;
        }
      }
    }
    .content {
      .view-cat-desc {
        .views-row {
          display: flex;
          border-bottom: 1px dotted #181a1c;
          margin-bottom: 15px;
          padding-bottom: 15px;

          .views-field-description {
            margin-left: 20px;
          }
        }
      }

      .view-faq {

        .title {
          font-weight: bold;
          cursor: pointer;
          margin: 10px 0;
          font-size: 18px;
        }
      }
      .view-uslugi {
        .view-content {
          .views-row {
            margin: 20px 8px;

            .field-content {
              margin-top: 10px;
              display: block;

              a {
                font-size: 18px;
                text-transform: uppercase;
                font-weight: bold;
                color: $title;
              }
            }
          }
        }
      }

      .view-taxonomy-term {
        .view-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;

          @include respond-to(320) {
            justify-content: center;
          }

          .views-row {
            width: 213px;
            border: 1px solid #c4c4c4;
            text-align: center;
            margin-bottom: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 18px;
            box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0);
            @include transite();

            &:hover {
              @include transite();
              box-shadow: 2px 2px 8px -1px rgba(0, 0, 0, 0.5);
            }

            img {
              width: 100%;
            }

            .views-field-title {
              a {
                color: $title;
                font-weight: bold;
                text-transform: uppercase;
                padding: 0 5px;
              }
            }
            .views-field-field-price {
              .field-content {
                color: $title;
                font-weight: bold;
                font-size: 24px;
                text-transform: uppercase;
              }
            }
            .views-field-view-node {
              a {
                display: block;
                padding: 10px 30px;
                background: #fecc02;
                color: #1b1d1f;
                font-weight: bold;
                text-align: center;
                text-transform: uppercase;
                margin: 22px 35px;

                &:hover {
                  @include transite();
                  background: $title;
                  color: $default;
                }
              }
            }
          }
        }
      }
    }

    .view-news {
      .node-article {
        display: flex;
        margin-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px dotted $title;

        @include respond-to(320) {
          flex-direction: column;

        }

        .group-left {
          @include respond-to(320) {
            text-align: center;
          }
        }

        .group-right {
          margin-left: 50px;
          display: block;
          position: relative;
          padding-bottom: 40px;

          @include respond-to(320) {
            padding-bottom: 30px;
          }

          @include respond-to(320) {
            margin-left: 0;
          }

          a.btn_loop {
            text-transform: uppercase;
            color: $title;
            font-weight: bold;
            position: absolute;
            bottom: 0;
            right: 0;
            background: $btn;
            display: block;
            font-size: 16px;
            padding: 5px 20px;
            @include transite();

            &:hover {
              background: $title;
              color: $default;
              @include transite()
            }
          }
        }
      }
    }
    .node-product {

      @include respond-to(320) {
        display: flex;
        flex-direction: column;
      }
      .group-left {
        display: inline-block;
        margin: 0 auto;
        img {
          border: 1px solid #c4c4c4;
        }

        .imgs {
          width: 366px;

          @include respond-to(768) {
            width: 259px;
          }

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
      .group-right {
        display: inline-block;
        vertical-align: top;
        margin-left: 55px;

        @include respond-to(768) {
          margin-left: 30px;
        }
        @include respond-to(320) {
          margin: 20px auto;
        }

        .sku {

          .label-above {
            font-weight: bold;
            color: $title;
            margin-bottom: 9px;
            display: inline-block;
          }
        }

        .price {

          .label-above {
            font-weight: bold;
            color: $title;
            margin-bottom: 9px;
          }
          span {
            color: #414141;
            font-size: 26px;
          }
        }
        p {
          a {
            display: block;
            padding: 10px 30px;
            background: #fecc02;
            color: #1b1d1f;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            margin-top: 19px;
            width: 142px;
            @include transite();

            &:hover {
              background: $title;
              color: $default;
              @include transite();
            }
          }
        }
      }
      .group-footer {
        display: block;

        .label-above {
          margin-top: 34px;
          font-size: 26px;
          font-weight: bold;
          text-transform: uppercase;
          color: $title;
        }
      }
    }

    .slick-prev, .slick-next {
      top: 95%
    }
    .slick-dots {
      position: relative;
      li {
        width: 110px;

        @include respond-to(768) {
          width: 76px;
        }
      }
    }

    .slick-dots li {
      display: none
    }
    .slick-dots li:nth-last-child(1),
    .slick-dots li:nth-last-child(2),
    .slick-dots li:nth-last-child(3) {
      display: inline-block;
    }

    .slick-prev {
      background: url(../img/icons/prev.png) no-repeat center;
      @include transite();

      &:hover {
        background: url(../img/icons/prev_hover.png) no-repeat center;
      }
    }
    .slick-next {
      background: url(../img/icons/next.png) no-repeat center;
      @include transite();

      &:hover {
        background: url(../img/icons/next_hover.png) no-repeat center;
      }
    }

    .node-webform {
      input {
        max-width: 444px;
        height: 30px;
        border: 1px solid #b4b4b6;
        margin-bottom: 8px;
        padding-left: 8px;
        font-style: italic;
        font-size: 15px;
        width: 100%;
      }

      textarea {
        max-width: 444px;
        padding-left: 8px;
        font-style: italic;
        margin-bottom: 8px;
        font-size: 16px;
      }
      input.webform-submit {
        background: $btn;
        width: 100%;
        color: $title;
        text-transform: uppercase;
        font-weight: bold;
        font-style: normal;
        @include transite();

        &:hover {
          background: $title;
          color: $default;
          @include transite();
        }
      }
    }
  }

  .node-uslugi {
    display: flex;
    margin-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px dotted #181a1c;

    @include respond-to(320) {
      flex-direction: column;
    }

    .group-left {
      @include respond-to(320) {
        text-align: center;
      }
    }

    .group-right {
      margin-left: 50px;
      display: block;
      position: relative;
      padding-bottom: 40px;

      @include respond-to(320) {
        margin-left: 0;
      }

      a.btn_loop {
        display: block;
        text-transform: uppercase;
        color: $title;
        font-weight: bold;
        position: absolute;
        bottom: 0;
        right: 0;
        background: $btn;
        font-size: 16px;
        padding: 5px 20px;

        &:hover {
          color: $default;
          background: $title;
          @include transite()
        }
      }
    }
  }
  footer {
    background: url(../img/footer_bg.jpg) center/cover;
    padding-top: 46px;

    .footer_info {
      background: url(../img/kl.png) no-repeat bottom;
      padding-bottom: 37px;

      .wrp {
        display: flex;
        justify-content: space-between;

        .region-footer-column-one {
          @include respond-to(320) {
            display: none;
          }
          p.block-title {
            font-size: 18px;
            font-weight: bold;
            color: $default;
          }
          .content {
            p {
              color: $default;
              padding: 12px 0;
            }
          }
        }
        .region-footer-column-two {
          @include respond-to(768) {
            display: none;
          }
          .block-title {
            display: none;
          }
          .block-menu {
            .content {
              ul {
                column-count: 2;
                margin-top: 40px;
                li {
                  margin-bottom: 21px;

                  a {
                    font-size: 16px;
                    color: $default;
                    @include transite();

                    &:hover {
                      color: $btn;
                    }
                  }
                }
              }
            }
          }
          .content {
            p {
              color: $default;
              padding: 12px 0;
            }
          }
        }
        .region-footer-column-three {
          @include respond-to(320) {
            margin: 0 auto;
          }

          .block-title {
            font-size: 18px;
            font-weight: bold;
            color: $default;
          }
          .block-webform {
            p.block-title {
              text-transform: uppercase;
              margin-bottom: 15px;
            }
            input.form-text {
              max-width: 444px;
              height: 30px;
              border: 1px solid #b4b4b6;
              margin-bottom: 8px;
              padding-left: 8px;
              font-style: italic;
              font-size: 15px;
              width: 100%;
            }
            textarea {
              max-width: 444px;
              padding-left: 8px;
              font-style: italic;
              font-size: 15px;
              width: 100%;
            }
            input.webform-submit {
              margin-top: 16px;
              height: 30px;
              border: 2px solid $default;
              background: $btn;
              width: 100%;
              color: $title;
              text-transform: uppercase;
              font-weight: bold;
              @include transite();

              &:hover {
                background: $title;
                color: $default;
                @include transite();
              }
            }
          }
          .content {

            ul.network {
              display: flex;

              li {
                margin: 20px 12px 0 0;

                &:hover {
                  position: relative;
                  top: 4px;
                }

                img {
                  width: 52px;
                  height: 52px;
                }
              }
            }
          }
        }
      }
    }
    .footer_bottom {
      .region-footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include respond-to(320) {
          flex-direction: column;
        }

        .content {
          p {
            color: $default;
          }
        }
      }
    }
  }
}

.imagess {
  display: flex;
  flex-wrap: wrap;

  @include respond-to(320) {
    justify-content: center;
  }

  img {
    margin: 10px 12px 0 0;
  }
}

.vopros {
  margin-top: 20px;

  a {
    width: 160px;
    background: $btn;
    color: $title;
    display: block;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 20px;
    text-transform: uppercase;
    @include transite();

    &:hover {
      background: $title;
      color: $default;
    }
  }
}

.whatsapp {
  width: 68px;
  position: fixed;
  right: 40%;
  transform: translateX(790%);
  bottom: 10%;
  z-index: 20;

  @include respond-to(960) {
    transform: none;
    right: 10px;
  }
  @include respond-to(768) {
    transform: none;
    right: 10px;
  }
  @include respond-to(320) {
    right: -10px;
  }
}

.view-search {
  .views-row {
    border-bottom: 1px dotted #181a1c;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .views-field-title {
      a {
        font-size: 20px;
        font-weight: bold;
        color: $title;
        @include transite();

        &:hover {
          color: $btn;
          @include transite();
        }
      }
    }
    .views-field-view-node {
      position: relative;
      padding-bottom: 40px;

      a {
        text-transform: uppercase;
        color: $title;
        font-weight: bold;
        position: absolute;
        bottom: 0;
        right: 0;
        background: $btn;
        display: block;
        font-size: 16px;
        padding: 5px 20px;
        @include transite();

        &:hover {
          background: $title;
          color: $default;
          @include transite();
        }
      }
    }
  }
}

.tabs {
  li {
    a {
      color: #000;
    }
  }
}

ul.pager {
  margin-top: 15px;

  li {
    color: $title;
    @include transite();

    &:hover {
      color: $btn;
      @include transite();
    }

    a {
      color: $title;
      @include transite();

      &:hover {
        color: $btn;
        @include transite();
      }
    }
  }
}

p {
  margin-bottom: 10px;
}

#user-login {
  input {
    padding: 5px;
    border: 1px solid;
  }
}

.field-name-field-img-uslugi {
  margin-top: 15px;
}

#block-system-main {

  .content {

  }
}

ul.preimuschestva, .network, .work {
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-faq {

  .block-webform {
    margin-top: 30px;

    p.block-title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  input {
    max-width: 444px;
    height: 30px;
    border: 1px solid #b4b4b6;
    margin-bottom: 8px;
    padding-left: 8px;
    font-style: italic;
    font-size: 15px;
    width: 100%;
  }
  textarea {
    max-width: 444px;
    padding-left: 8px;
    font-style: italic;
    margin-bottom: 8px;
    font-size: 16px;
  }
  input.webform-submit {
    background: $btn;
    width: 100%;
    color: $title;
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    @include transite();

    &:hover {
      background: $title;
      color: $default;
      @include transite();
    }
  }
}

.group-right {
  h2 {
    font-size: 20px;
    text-transform: uppercase;
  }
}

.node-type-product {
  h1.title {
    @include respond-to(320) {
      text-align: center;
    }
  }
}

#block-block-10 {
  a {
    margin-top: 5px;
    display: block;
    color: #fff;
    @include transite();

    &:hover {
      color: $btn;
      @include transite();
    }
  }
}

.field-name-field-img-uslugi {
  img {

    &:first-child {
      display: none;
    }
  }
}

.imagess {

  .img:first-child {
    display: none;
  }
}

.expanded:before {
  content: '▼';
  color: #fff;
  padding-left: 5px;
  position: absolute;
  left: 45px;
}

.abrakadabra {
  font-weight: bold;
}

.about {
  display: none;
}

.region-footer-bottom {
  p {
    margin-bottom: 0;
  }

  img {
    margin: 10px;
  }
}

#block-views-exp-search-page {

  .views-exposed-widgets {
    position: relative;

    &:hover {

      label {
        opacity: 1;
        visibility: visible;
        @include transite();
      }
    }

    label {
      font-size: 14px;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: -29px;
      border: 1px solid #ddd;
      background: #eee;
      padding: 3px 10px;
      border-radius: 3px;
      @include transite();

      &:before {
        content: '';
        position: absolute;
        border: 10px solid transparent;
        border-bottom: 10px solid #ddd;
        bottom: 22px;
        left: 10px;
      }

      &:after {
        content: '';
        position: absolute;
        border: 10px solid transparent;
        border-bottom: 10px solid #eee;
        bottom: 21px;
        left: 10px;
      }
    }
  }
}
